import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions } from "../actions";
import axios from 'axios';
import _ from 'lodash';
import { helpers } from "@cargo/common";
import selectors from '../selectors'
import { Alert, AlertContext } from "@cargo/ui-kit";
import { withRouter } from 'react-router-dom';
import { commands } from "../lib/inline-editor";
import { cleanupNestedSpansOrLinksOrHeadersCreatedDuringMutation } from '../lib/inline-editor/helpers';
import marqueeSetPlugin from "../lib/inline-editor/plugins/marquees";
import columnsPlugin from "../lib/inline-editor/plugins/columns";
import embedsPlugin from "../lib/inline-editor/plugins/embeds";
import copyPastePlugin from "../lib/inline-editor/plugins/copypaste";
import globalDragEventController from "./drag-event-controller";
import { getSupportedFileTypes } from './ui-kit/helpers';
import { HotKeyProxy } from './ui-kit';
import { isLocalEnv } from "@cargo/common/helpers";
import { overlayDefaults } from '../defaults/overlay-defaults';

import { createDOMBinding, domBindingsMap, observeInlineEditorMutations } from "../lib/shared-dom";
import { uploadMedia, convertFileToImage, convertFileToVideo } from "../lib/media";
import { FRONTEND_DATA, CRDTState, YTransactionTypes } from "../globals";
import { getDefaultEditingPID, getFirstPageInSet } from "../lib/page-list-manager";
import { globalUndoManager } from "../lib/undo-redo";
import { getCRDTItem, applyChangesToYType, convertStateToSharedType } from "../lib/multi-user/redux";
import { Y, ydoc } from "../lib/multi-user";

export const EditorContext = React.createContext({});

let contentFetchesInProgress = [];
let lastEditorRangeAtMutationStart;
let lastEditorRangeAtMutationEnd;

/**
 * Compute the path from this type to the specified target.
 *
 * @example
 *   // `child` should be accessible via `type.get(path[0]).get(path[1])..`
 *   const path = type.getPathTo(child)
 *   // assuming `type instanceof YArray`
 *   console.log(path) // might look like => [2, 'key1']
 *   child === type.get(path[0]).get(path[1])
 *
 * @param {AbstractType<any>} parent
 * @param {AbstractType<any>} child target
 * @return {Array<string|number>} Path to the target
 *
 * @private
 * @function
 */
const getPathTo = (parent, child) => {
	const path = []
	while (child && child._item !== null && child !== parent) {
		if (child._item.parentSub !== null) {
			// parent is map-ish
			path.unshift(child._item.parentSub)
		} else {
			// parent is array-ish
			let i = 0
			let c = /** @type {AbstractType<any>} */ (child._item.parent)._start
			while (c !== child._item && c !== null) {
				if (!c.deleted) {
					i++
				}
				c = c.right
			}
			path.unshift(i)
		}
		child = /** @type {AbstractType<any>} */ (child._item.parent)
	}
	return path
}

const getValueAtPath = (type, path) => {

	for (let i = 0; i < path.length; i++) {

		if (!type || !type.get) {
			return;
		}

		type = type.get(path[i]);

	}

	return type;

}


class PageEditor extends Component {

	constructor(props) {

		super(props);

		let commandStates = {};

		_.each(commands, (commandObj, commandName) => {

			let commandState = {
				getState: commandObj.getState,
				priority: commandObj.priority || -1
			};

			commandStates[commandName] = commandState;
		})

		this.state = {
			editorContext: {
				range: undefined,
				getCommandState: commandStates,
				editorHasFocus: false
			}
		}

		this.elementsAwaitingEditor = [];

		// we want to destroy the editor instantly when an unmount is detected. Using
		// mapStateToProps won't tell us about the change till componentDidUpdate is called
		// which leaves the editor in limbo till then
		let lastRenderedPages;

		this.unsubscribeFromStore = store.subscribe(() => {

			const state = store.getState();
			const renderedPages = state.frontendState.renderedPages;

			if(renderedPages !== lastRenderedPages) {

				const mountedPages = _.difference(renderedPages, lastRenderedPages);
				const unMountedPages = _.difference(lastRenderedPages, renderedPages);

				const mountedPageEl = _.findLast(mountedPages, el => el.id === state.frontendState.PIDBeingEdited);
				const unmountedPageEl = _.findLast(unMountedPages, el => el.id === state.frontendState.PIDBeingEdited);

				if(unmountedPageEl) {
					this.destroyInlineEditor();
				}

				if(mountedPageEl) {
					this.makePageInlineEditable(mountedPageEl)
				}
			}

			lastRenderedPages = renderedPages;

		});

	}

	bindContentWindowEvents = () => {


		FRONTEND_DATA.contentWindow.document.addEventListener("mousedown", this.onMouseDown, false);

		FRONTEND_DATA.contentWindow.addEventListener('y-dom-binding-type-changed', this.onExternalDomBindingChanges, false);

	}

	unbindContentWindowEvents = () => {

		FRONTEND_DATA.contentWindow.document.removeEventListener("mousedown", this.onMouseDown, false);
		FRONTEND_DATA.contentWindow.removeEventListener('pointermove', this.onPointerMove)

		FRONTEND_DATA.contentWindow.removeEventListener('y-dom-binding-type-changed', this.onExternalDomBindingChanges);

	}

	onExternalDomBindingChanges = e => {

		requestAnimationFrame(() => {
			this.onEditorCursorActivity();
		});

		if(this.activeEditor) {
			this.activeEditor.checkIfEmpty();
		}

	}

	getRangeSummary(range) {

		const domBinding = domBindingsMap.get(this.props.pid);

		if (!domBinding || !range) {
			return;
		}

		// console.log('serialized', {
		// 	startContainerTypePath: getPathTo(domBinding.type, domBinding.domToType.get(range.startContainer)),
		// 	endContainerTypePath: getPathTo(domBinding.type, domBinding.domToType.get(range.endContainer)),
		// 	startContainer: range.startContainer,
		// 	startOffset: range.startOffset,
		// 	endContainer: range.endContainer
		// })

		const result = {
			startContainerTypePath: getPathTo(domBinding.type, domBinding.domToType.get(range.startContainer)),
			endContainerTypePath: getPathTo(domBinding.type, domBinding.domToType.get(range.endContainer)),
			startContainer: null,
			startOffset: range.startOffset,
			endContainer: null,
			endOffset: range.endOffset,
			collapsed: range.collapsed,
			setStart: function (a, b) {
				this.startContainer = a;
				this.startOffset = b;
			},
			setEnd: function (a, b) {
				this.endContainer = a;
				this.endOffset = b;
			}
		}

		// when a new element is created, the DOM binding might not have it associated
		// until it's own DOM observer callback has been handled. Below are some promises
		// that'll wait till we have a type for the range's start & end nodes
		domBinding.awaitDomNodeAssociation(range.startContainer).then(type => {
			result.startContainerTypePath = getPathTo(domBinding.type, type);
		});

		domBinding.awaitDomNodeAssociation(range.endContainer).then(type => {
			result.endContainerTypePath = getPathTo(domBinding.type, type);
		});

		return result;

	}

	restoreRangeSummary(serializedRange) {

		const domBinding = domBindingsMap.get(this.props.pid);

		if (!serializedRange || !domBinding) {
			return;
		}

		// don't mutate the original range
		serializedRange = { ...serializedRange }

		// Grab the start and end container types based on their paths
		const startContainerType = getValueAtPath(domBinding.type, serializedRange.startContainerTypePath);
		const endContainerType = getValueAtPath(domBinding.type, serializedRange.endContainerTypePath);

		if (!startContainerType || !endContainerType) {
			console.log('unable to retrieve range boundary nodes', serializedRange)
			return;
		}

		// deserialize start and end containers first
		serializedRange.startContainer = domBinding.typeToDom.get(startContainerType);
		serializedRange.endContainer = domBinding.typeToDom.get(endContainerType);

		if (!serializedRange.startContainer || !serializedRange.endContainer) {
			console.log('unable to retrieve range boundary nodes', serializedRange)
			return;
		}

		// Don't want a botched selection restore attempt to break the whole script.
		try {

			var selection = FRONTEND_DATA.contentWindow.getSelection(),
				range = FRONTEND_DATA.contentWindow.document.createRange();

			this.CargoEditor.helpers.fixRangeOffsets(serializedRange);

			range.setStart(serializedRange.startContainer, serializedRange.startOffset);
			range.setEnd(serializedRange.endContainer, serializedRange.endOffset);

			if (serializedRange.collapsed) {
				range.collapse();
			}

			selection.removeAllRanges();
			selection.addRange(range);

			const rangeRect = range.getBoundingClientRect();
			const windowHeight = FRONTEND_DATA.contentWindow.innerHeight;

			if(rangeRect.y < 0) {
				FRONTEND_DATA.contentWindow.document.scrollingElement.scrollBy(0, rangeRect.y);
			} else if(rangeRect.y > windowHeight) {
				FRONTEND_DATA.contentWindow.document.scrollingElement.scrollBy(0, rangeRect.y - (windowHeight - rangeRect.height));
			}

		} catch (e) {
			console.error(e);
		}

	}


	loadInlineEditor() {

		const onEditorLoad = () => {

			this.CargoEditor = FRONTEND_DATA.contentWindow.CargoEditor;
			window.CargoEditor = FRONTEND_DATA.contentWindow.CargoEditor;

			// plugins coming from the admin repo get a destroy method to make them compatible with hot-reloading
			this.CargoEditor.plugins.marquees?.destroy?.();			
			this.CargoEditor.plugins.columns?.destroy?.();

			this.CargoEditor.addPlugin('marquees', marqueeSetPlugin);
			this.CargoEditor.addPlugin('columns', columnsPlugin);
			this.CargoEditor.addPlugin('embeds', embedsPlugin);
			this.CargoEditor.addPlugin('copypaste', copyPastePlugin);

			this.bindContentWindowEvents();
			this.bindEditorEvents();
			observeInlineEditorMutations(this.CargoEditor);

			FRONTEND_DATA.contentWindow.dispatchEvent(new CustomEvent('CargoEditor-load', {
				bubbles: true,
				composed: true,
				detail: {}
			}));

			window.dispatchEvent(new CustomEvent('CargoEditor-load', {
				bubbles: true,
				composed: true,
				detail: {}
			}));

			this.ranInitialEditorLoad = true;

		}

		// inject the inline editor's JS into the client frame and return a promise to indicate
		// the Inline Editor is ready for use.
		return new Promise((resolve, reject) => {

			if (_.keys(FRONTEND_DATA.contentWindow.CargoEditor).length !== 0) {

				// make sure editor load is only called once during the component's lifecycle
				if (this.ranInitialEditorLoad !== true) {
					onEditorLoad();
				}

				return resolve(FRONTEND_DATA.contentWindow.CargoEditor);
			}

			const editorScript = FRONTEND_DATA.contentWindow.document.createElement('script')
			editorScript.onerror = reject;
			editorScript.onload = () => {

				// we'll have to remove underscore from the editor plugins eventually.
				FRONTEND_DATA.contentWindow._ = require('underscore');

				// load plugins
				const pluginsScript = FRONTEND_DATA.contentWindow.document.createElement('script');

				pluginsScript.onerror = reject;
				pluginsScript.onload = () => {

					setTimeout(() => {

						if (this.destroyed) {
							return;
						}

						// make sure editor load is only called once during the component's lifecycle
						if (this.ranInitialEditorLoad !== true) {
							onEditorLoad();
						}

						_.each(commands, (commandObj) => {

							if (typeof commandObj.onEditorLoad === 'function') {
								commandObj.onEditorLoad();
							}

						})

						resolve(this.CargoEditor);

					}, 250);

				};

				if(isLocalEnv) {
					pluginsScript.src = PUBLIC_URL + '/inline-editor/cargoEditor_plugins_c3_all.js';
				} else {
					pluginsScript.src = PUBLIC_URL + '/inline-editor/cargoEditor_plugins_c3_all.min.js';
				}

				FRONTEND_DATA.contentWindow.document.head.appendChild(pluginsScript);

			};


			if(isLocalEnv) {
				editorScript.src = PUBLIC_URL + '/inline-editor/cargoEditor.js';
			} else {
				editorScript.src = PUBLIC_URL + '/inline-editor/cargoEditor.min.js';
			}
			FRONTEND_DATA.contentWindow.document.head.appendChild(editorScript);

		});

	}

	render() {

		return <EditorContext.Provider value={{ ...this.state.editorContext, registerCommand: this.registerCommand }}>
			{this.props.children || null}
		</EditorContext.Provider>

	}

	registerCommand = (commandName, getState, priority) => {

		this.setState(state => {

			state.editorContext.getCommandState[commandName] = {
				getState: getState,
				priority: priority || -1
			};
			return state;

		});

	}

	gainFocus = () => {

		this.setState(state => {
			return {
				editorContext: {
					...state.editorContext,
					editorHasFocus: true
				}
			}
		});
	}

	loseFocus = () => {
		this.setState(state => {
			return {
				editorContext: {
					...state.editorContext,
					editorHasFocus: false
				}
			}
		});
	}

	bindEditorEvents() {

		this.CargoEditor.events.on('cursor-activity', this.onEditorCursorActivity);
		this.CargoEditor.events.on('mutation-end', this.onEditorMutationEnd, undefined, {trailing: true});
		this.CargoEditor.events.on('mutation-start', this.onEditorMutationStart);
		this.CargoEditor.events.on('editor-activated', this.gainFocus);
		this.CargoEditor.events.on('editor-deactivated', this.loseFocus);
		this.CargoEditor.events.on('editor-no-content', this.editorIsEmpty);
		this.CargoEditor.events.on('editor-has-content', this.editorHasContent);
		this.CargoEditor.events.on('mutation-before-success', this.beforeEditorMutationSuccess);
		this.CargoEditor.events.on('editor-pasted-link', this.onEditorLinkPaste);

		globalDragEventController.on('dragstart', this.onDragStart);
		globalDragEventController.on('dragend', this.onDragEnd);

		globalDragEventController.on('drop', this.onEditorDrop)
		globalDragEventController.on('before-drop', this.onBeforeEditorDrop)

		// bind to the undo manager
		globalUndoManager.on('yjs-stackitem-start', this.onYJSUndoStackItemStarted);
		globalUndoManager.on('yjs-stackitem-finalized', this.onYJSUndoStackItemFinalized);
		globalUndoManager.on('yjs-before-stackitem-applied', this.beforeYJSUndoStackItemApplied);
		globalUndoManager.on('yjs-stackitem-applied', this.onYJSUndoStackItemApplied);

		// get initial cursor information
		this.onEditorCursorActivity();

	}

	onYJSUndoStackItemStarted = (stackItem) => {

		if (stackItem.meta.has('undo-range') === false) {
			stackItem.meta.set('undo-range', lastEditorRangeAtMutationStart)
		}

	}

	onYJSUndoStackItemFinalized = (stackItem) => {

		if (stackItem.meta.has('redo-range') === false) {
			stackItem.meta.set('redo-range', lastEditorRangeAtMutationEnd)

		}

	}

	onUndoChangesObserved = (mutationRecords) => {

		// Ensure change made by undo/redo are saveable.
		mutationRecords.forEach(({ addedNodes }) => {
			addedNodes.forEach(node => {
				node.setSaveable(true);
			})
		})

		if(this.activeEditor) {
			this.activeEditor.checkIfEmpty();
		}

	}

	beforeYJSUndoStackItemApplied = (stackItem) => {

		// observe changes to the DOM and mark them as saveable
		if (this.activeEditor) {

			var observer = new MutationObserver(this.onUndoChangesObserved);

			observer.observe(this.activeEditor.getElement(), {
				childList: true,
				subtree: true
			});

			stackItem.meta.set('change-observer', observer);

		}

	}

	onYJSUndoStackItemApplied = (stackItem, actionType) => {

		if (stackItem.meta.has('change-observer')) {

			const observer = stackItem.meta.get('change-observer');

			// report queued changes
			this.onUndoChangesObserved(observer.takeRecords())

			// remove the observer
			observer.disconnect();
			stackItem.meta.delete('change-observer');

		}

		// stop change observer, grab changes and mark them as saveable
		if (actionType === 'undo') {
			this.restoreRangeSummary(stackItem.meta.get('undo-range'));
		} else {
			this.restoreRangeSummary(stackItem.meta.get('redo-range'));
		}

	}

	onBeforeEditorDrop = (editor, e, dragData) => {

		if (editor !== this.activeEditor || dragData.inAdmin || !dragData.fromOutside) {
			return;
		}

		let droppedFiles = dragData.dataTransfer.get('files');

		/**
		 * Handle a new media item that hasn't been uploaded yet.
		 */
		if (
			droppedFiles && droppedFiles?.length > 0 &&
			dragData.fromOutside &&
			!dragData.fromAdmin &&
			!dragData.inAdmin
		) {

		
			const placeholderMap = new WeakMap();

			// create empty placeholders and insert them into the dragged list
			let draggedNodes = droppedFiles.map(file => {

				const placeholderId = _.uniqueId(ydoc.guid + '-');

				// videos that are less than 25mb are handled as images
				if (
					getSupportedFileTypes('image').includes(file.type) ||
					getSupportedFileTypes('video').includes(file.type)
				) {

					const finalImagePlaceholder = FRONTEND_DATA.contentWindow.document.createElement('media-item');

					finalImagePlaceholder.setAttribute('hash', 'placeholder');
					finalImagePlaceholder.setAttribute('data-placeholder', placeholderId);
					finalImagePlaceholder.setSaveable(true);

					placeholderMap.set(file, placeholderId)

					return finalImagePlaceholder;
				}

				if ( getSupportedFileTypes('audio').includes(file.type) ) {	

					const finalAudioPlaceholder = FRONTEND_DATA.contentWindow.document.createElement('audio-player');
					finalAudioPlaceholder.setAttribute('data-placeholder', placeholderId);

					placeholderMap.set(file, placeholderId)

					return finalAudioPlaceholder;
				}

			})

			// insert placeholders
			droppedFiles.forEach(async (file, i) => {

				const { CRDTItem: pageCRDT } = getCRDTItem({
					reducer: "pages.byId",
					item: this.props.pid
				});

				// keep a reference to the placeholder so we can update/remove it 
				// when uploading succeeds or fails
				switch ( true ) {
					case (
						getSupportedFileTypes('image').includes(file.type) ||
						getSupportedFileTypes('video').includes(file.type)
					): {

						const isVideo = getSupportedFileTypes('video').includes(file.type);
						const mediaItem = draggedNodes[i];

						if( isVideo ){
							file.onElementCreation = (element)=>{
								mediaItem.setAttribute('width', element.getAttribute('width'))
								mediaItem.setAttribute('height', element.getAttribute('height'))
								mediaItem.setAttribute('dynamic-filetype', 'video')									
								mediaItem.setAttribute('dynamic-src', element.src);
							}
							file.onBlobCreation= (blob)=>{
								const blobURL = URL.createObjectURL(blob);
								mediaItem.setAttribute('dynamic-poster', blobURL);
							}
						} else {
							file.onElementCreation = (element)=>{
								mediaItem.setAttribute('width', element.getAttribute('width'))
								mediaItem.setAttribute('height', element.getAttribute('height'))									
								mediaItem.setAttribute('dynamic-filetype', 'image');									
								mediaItem.setAttribute('dynamic-src', element.src);
							}
						}

						uploadMedia({
							target: pageCRDT,
							field: 'media'
						}, [file])[0]
							.then((uploadedMedia) => {

								const placeholderId = placeholderMap.get(file);
								const placeholderXmlElements = Array.from(pageCRDT.get('content')?.createTreeWalker?.(element => {
									return element instanceof Y.XmlElement && element.getAttribute('data-placeholder') === placeholderId;
								}) || []);

								ydoc.transact(() => {

									placeholderXmlElements.forEach(xmlElement => {

										if(!uploadedMedia) {
											// delete the placeholder from CRDT
											const indexInParent = xmlElement.parent.toArray().indexOf(xmlElement);

											if(indexInParent !== -1) {
												xmlElement.parent.delete(indexInParent, 1)
											}
										}

										xmlElement.setAttribute("hash", uploadedMedia.model.hash);

										if( uploadedMedia.model.is_video){

											if(!uploadedMedia.model.has_audio_track || uploadedMedia.model.duration < 15 ){

												xmlElement.setAttribute('autoplay', true);
												xmlElement.setAttribute('muted', true);
												xmlElement.setAttribute('loop', true);

											} else {
												xmlElement.setAttribute('browser-default', true);

												if( uploadedMedia.model.duration  < 20 ){
													xmlElement.setAttribute('loop', true);
												}
											}

										}

										const posterBlobURL = xmlElement.getAttribute('dynamic-poster');
										const blobURL = xmlElement.getAttribute('dynamic-src');

										setTimeout(()=>{

											if( posterBlobURL ){
												xmlElement.removeAttribute('dynamic-poster');
												URL.revokeObjectURL(posterBlobURL);
											}

											if( blobURL ){
												xmlElement.removeAttribute('dynamic-src');
												URL.revokeObjectURL(blobURL);
											}

										}, 100);

										xmlElement.removeAttribute('data-placeholder');
										xmlElement.removeAttribute('width');
										xmlElement.removeAttribute('height');
										xmlElement.removeAttribute('dynamic-src');
										xmlElement.removeAttribute('dynamic-poster');
										xmlElement.removeAttribute('dynamic-filetype');

										// check to see if we have the original and ensure we kill 
										// ignored attributes from it
										const node = domBindingsMap.get(pageCRDT.get('id'))?.typeToDom.get(xmlElement);

										if(node) {
											node.removeAttribute('dynamic-src');
											node.removeAttribute('dynamic-poster');
											node.removeAttribute('dynamic-filetype');
										}

									});

								}, YTransactionTypes.NotUndoable);

							})
							.catch(err => {
		
								console.error(err);

								const placeholderId = placeholderMap.get(file);

								const placeholderXmlElements = Array.from(pageCRDT.get('content')?.createTreeWalker?.(element => {
									return element instanceof Y.XmlElement && element.getAttribute('data-placeholder') === placeholderId;
								}) || []);

								ydoc.transact(() => {

									placeholderXmlElements.forEach(xmlElement => {

										// delete the placeholder from CRDT
										const indexInParent = xmlElement.parent.toArray().indexOf(xmlElement);

										if(indexInParent !== -1) {
											xmlElement.parent.delete(indexInParent, 1)
										}
									});

								}, YTransactionTypes.NotUndoable);
		
							});
						break;
					}
					case ( getSupportedFileTypes('audio').includes(file.type) ): {

						const audioPlayer = draggedNodes[i];
						audioPlayer.setAttribute("src", window.URL.createObjectURL(file));
						audioPlayer.setAttribute("label", file.name);

						uploadMedia({
							target: getCRDTItem({ reducer: 'media' }),
							field: 'data'
						}, [file])[0]
							.then(uploadedAudio => {

								const placeholderId = placeholderMap.get(file);

								const placeholderXmlElements = Array.from(pageCRDT.get('content')?.createTreeWalker?.(element => {
									return element instanceof Y.XmlElement && element.getAttribute('data-placeholder') === placeholderId;
								}) || []);

								ydoc.transact(() => {

									placeholderXmlElements.forEach(xmlElement => {

										xmlElement.removeAttribute('data-placeholder');
										const url = `https://freight.cargo.site/original/i/${uploadedAudio.model.hash}/${uploadedAudio.model.name}`;
										xmlElement.setAttribute("src", url);

									});

								}, YTransactionTypes.NotUndoable);

							})
							.catch(err => {

								console.error(err);
								const placeholderId = placeholderMap.get(file);

								const placeholderXmlElements = Array.from(pageCRDT.get('content')?.createTreeWalker?.(element => {
									return element instanceof Y.XmlElement && element.getAttribute('data-placeholder') === placeholderId;
								}) || []);

								ydoc.transact(() => {

									placeholderXmlElements.forEach(xmlElement => {

										// delete the placeholder from CRDT
										const indexInParent = xmlElement.parent.toArray().indexOf(xmlElement);

										if(indexInParent !== -1) {
											xmlElement.parent.delete(indexInParent, 1)
										}
									});

								}, YTransactionTypes.NotUndoable);

							});
						break;
					}

					default: {
						// this.props.addUIWindow({
						// 	group: 'right-menu-bar',
						// 	component: import('./right-menu-bar/media-window'),
						// 	id: `media-window`,
						// 	props: {
						// 		type: 'popover',
						// 		uiWindowType: 'popover',
						// 		windowName: 'media',
						// 		positionType: 'center-under-button',
						// 		acceptDrops: true,
						// 		allowedWindow: 'pageoptions-window',
						// 		buttonPos: document.querySelector('button[name="images"]')?.getBoundingClientRect() ?? null,
						// 	}
						// },{
						// 	removeGroup: false
						// });					
		
						setTimeout(()=>{
		
							this.props.addUIWindow({
								group: 'right-menu-bar',
								component: import('./right-menu-bar/library-window'),
								id: `library-window`,
								props: {
									type: 'popover',
									uiWindowType: 'popover',
									borderRadius: 'radius-all',
									// clickoutLayer: true,
									windowName: 'library',
									className: 'files',
									closeOnSingleClickout: true,
									invokeTarget: null,
									invokeWindow: 'media-window',
									closeButton: false,
									positionType: 'center',
									waitForHeightBeforeRender: true,
									minimumRenderHeight: 50,
									avoidTransform: true,
									acceptDrops: true,
									buttonPos: document.querySelector('button.files-button')?.getBoundingClientRect() ?? null,
									mediaType: 'files'
								}
							},{
								removeGroup: false
							});	
		
							setTimeout(()=>{
								uploadMedia({
									target: getCRDTItem({ reducer: 'media' }),
									field: 'data'
								}, [file])[0]
									.then(result => {							
										console.log(result, "Files added to page library!")
								})
							}, 250)
		
						}, 250)
						draggedNodes[i] = document.createTextNode('')
					}
				}
			});
			// insert uploaded files into dragged nodes
			globalDragEventController.setDraggedNodes([...dragData.draggedNodes, ...draggedNodes]);
		}

	}

	onDragStart = ()=>{
		this.dragging = true;
		FRONTEND_DATA.contentWindow.removeEventListener('pointermove', this.onPointerMove)		
		FRONTEND_DATA.contentWindow.removeEventListener('pointerup', this.onPointerUp)
		window.removeEventListener('pointerup', this.onPointerUp)
	}

	onDragEnd = ()=>{
		this.dragging = false;
	}

	onEditorDrop = (editor, e, dragData) => {

		this.dragging = false;

		if (editor !== this.activeEditor || dragData.inAdmin) {
			return;
		}

		// custom drop targets have their own insertion logic, so bail out if it's detected.
		if (!dragData.customDropTarget) {

			this.CargoEditor.mutationManager.execute(() => {

				let draggedNodes = dragData.draggedNodes;

				if (draggedNodes.length == 0) {
					return;
				}

				let lastInserted = null;
				let editorEl = this.activeEditor.getElement();

				// split boundaries if we're dragging a text node around
				// if dragging from outside of window, dragRange will be undefined
				if (
					(draggedNodes[0].nodeType === Node.TEXT_NODE ||
						draggedNodes[draggedNodes.length - 1].nodeType === Node.TEXT_NODE)
					&& dragData.dragRange
				) {
					dragData.dragRange.splitBoundaries();
					draggedNodes = dragData.dragRange.getNodes();

					if (!dragData.dragRange.commonAncestorContainer.nodeType === Node.TEXT_NODE) {
						draggedNodes = draggedNodes.filter(node => {
							return node.parentNode == dragData.dragRange.commonAncestorContainer
						})
					}
				}


				draggedNodes.forEach(node => {

					let toInsert = [node];

					if (node.nodeName === 'A') {
						var hasTextBefore = node.previousSibling !== null && node.previousSibling.nodeType === Node.TEXT_NODE && this.CargoEditor.helpers.whiteSpaceHelpers.is_all_ws(node.previousSibling) === false,
							hasTextAfter = node.nextSibling !== null && node.nextSibling.nodeType === Node.TEXT_NODE && this.CargoEditor.helpers.whiteSpaceHelpers.is_all_ws(node.nextSibling) === false;

						if (hasTextBefore && hasTextAfter) {
							toInsert.unshift(document.createElement('br'));
							toInsert.push(document.createElement('br'));

						}
					}

					toInsert.forEach(nodeToInsert => {

						if(nodeToInsert.nodeName==='MEDIA-ITEM'){
							nodeToInsert.removeAttribute('style');
						}

						let closestMediaItemAtSource = helpers.closest(nodeToInsert, function (el) {
							return el.nodeName === 'MEDIA-ITEM'
						});

						if (lastInserted) {
							lastInserted.parentNode.insertBefore(nodeToInsert, lastInserted.nextSibling);
						} else {
							dragData.insertionRange.insertNode(nodeToInsert);
						}

						lastInserted = nodeToInsert;

					});

				});

				this.CargoEditor.forcedRange = null;
				this.CargoEditor.events.trigger('cursor-activity', editor);

			});

		}
	}

	onPointerUp = (e) => {

		FRONTEND_DATA.contentWindow.removeEventListener('pointermove', this.onPointerMove)		
		FRONTEND_DATA.contentWindow.removeEventListener('pointerup', this.onPointerUp)
		window.removeEventListener('pointerup', this.onPointerUp)		

		if( !this.activeEditor ){
			return
		}

		if( this.pointerInfo?.willDeselectOnPointerUp){

			// exempt text-icon from click-to-select behavior...

			const {
				correctedRange,
				clickRange
			} = this.forceRangeInsideEditor(e);

			const newRange = correctedRange || clickRange;

			if( !newRange.collapsed ){

				// use cursor position to determine whether range goes before or after
				const elRect = e.target.getBoundingClientRect();

				newRange.selectNode(e.target);
				
				if( e.clientX > elRect.left + elRect.width*.5){
					newRange.collapse(false);
				} else {
					newRange.collapse(true);
				}
			}

			if( this.CargoEditor.plugins.shadowDom?.canBeEntirelySelected(e.target) ){
				this.CargoEditor.forcedRange = null;
				this.CargoEditor.rangy.getSelection().removeAllRanges();
			} else {
				this.CargoEditor.helpers.setActiveRange(newRange);
			} 

			this.CargoEditor.events.trigger('cursor-activity', this.CargoEditor.getActiveEditor());

		} else if( this.pointerInfo?.selectionDragged ){

			this.CargoEditor.events.trigger('cursor-activity', this.CargoEditor.getActiveEditor());

		} else if (

			!this.pointerInfo?.willDeselectOnPointerUp &&
			!e.shiftKey && 
			this.CargoEditor.plugins.shadowDom?.canBeEntirelySelected(e.target)

		){

			const newRange = this.CargoEditor.rangy.createRange();
			newRange.selectNode(e.target);

			const activeRange = this.CargoEditor.getActiveRange();
			if( !activeRange || !newRange.equals(activeRange)){
				this.CargoEditor.helpers.setActiveRange(newRange);
				this.CargoEditor.events.trigger('cursor-activity', this.CargoEditor.getActiveEditor());				
			}

		} else if (e.target.nodeName.startsWith('GALLERY-')){
			this.CargoEditor.forcedRange = null;
			this.CargoEditor.rangy.getSelection().removeAllRanges();	
			this.CargoEditor.events.trigger('cursor-activity', this.CargoEditor.getActiveEditor());

		}

	}

	onPointerMove = (e)=>{

		if(e.defaultPrevented){
			return
		}
		e.preventDefault();

		if( e.button == 2 || e.target.id =='editor-overlay'){
			return;
		}		

		const pointer = {
			pointerdown: true,
			x: e.clientX,
			y: e.clientY,
			delta: 0,
			willDeselectOnPointerUp: false,
		}
		if( !this.pointerInfo ){
			this.pointerInfo = pointer;
		}

		if(!this.pointerInfo.initialRange){
			return;
		}


		const move = Math.sqrt( Math.pow(this.pointerInfo.x - pointer.x, 2) + Math.pow(this.pointerInfo.y - pointer.y, 2)   );
		this.pointerInfo.delta = this.pointerInfo.delta + move;

		this.pointerInfo.x = pointer.x;
		this.pointerInfo.y = pointer.y;

		if( this.pointerInfo.delta > 3 && !this.ticking){

			this.pointerInfo.willDeselectOnPointerUp = false;
			this.ticking = true;

			requestAnimationFrame(()=>{
				this.ticking = false;
			})			

			const {
				correctedRange,
				clickRange
			} = this.forceRangeInsideEditor(e);

			if(clickRange|| correctedRange){
				var newRange = this.shiftSelect(clickRange || correctedRange, e);

				this.CargoEditor.helpers.setActiveRange(newRange);

				this.CargoEditor.events.trigger('cursor-activity', this.activeEditor);
				this.pointerInfo.selectionDragged = true;				
				this.pointerInfo.willDeselectOnPointerUp = false;						
			}
					
		} 
	}

	onMouseDown = (e)=>{

		if(
			e.button == 2 
			|| e.target.id =='editor-overlay'
			|| e.target.closest?.('.contact-form')
		){
			return;
		}


		if(
			!e.target.shadowRoot &&
			(
				e.defaultPrevented || 
				!this.activeEditor
			)
		){
			return
		}

		if(!this.activeEditor) {
			return;
		}

		const clickedOnDropDown = Array.from(e.composedPath()).some(el=>el.tagName==='SELECT');
		if(clickedOnDropDown){
			return
		}

		const editorElement = this.activeEditor.getElement();

		window.addEventListener('pointerup', this.onPointerUp)
		FRONTEND_DATA.contentWindow.addEventListener('pointerup', this.onPointerUp)


		var {
			correctedRange,
			clickRange,
			clickedInsideActiveRange
		} = this.forceRangeInsideEditor(e);


		// when clicking around the page and the page does not include the click target, it might
		// still get corrected to be a range inside the link
		// this *feels* incorrect so we should close the relevant ui window

		if( !editorElement.contains(e.target)  ){

			this.props.removeUIWindow(uiWindow => {
				return 'formatting/link' == uiWindow.id
	  		});

		}

		editorElement.focus({
			preventScroll: true
		});	

		if( e.shiftKey && this.pointerInfo && (correctedRange || clickRange)){

			e.preventDefault();
			FRONTEND_DATA.contentWindow.addEventListener('pointermove', this.onPointerMove)

			this.pointerInfo.initialRange = this.CargoEditor.getActiveRange();

			this.pointerInfo.willDeselectOnPointerUp = false;
			var newRange = this.shiftSelect(correctedRange || clickRange, e);
			this.CargoEditor.helpers.setActiveRange(newRange);

			this.CargoEditor.events.trigger('cursor-activity', this.activeEditor);
			this.pointerInfo.selectionDragged = true;				
			this.pointerInfo.willDeselectOnPointerUp = false;

			return;

		}  else {

			this.pointerInfo = {
				click: e.detail,
				pointerdown: true,
				delta: 0,
				x: e.clientX,
				y: e.clientY,
				initialRange: correctedRange || clickRange,
				selectionDragged: false,
				willDeselectOnPointerUp: false,
			}
		}

		if ((!clickRange && !correctedRange)){
			this.pointerInfo = {
				click: e.detail,				
				pointerdown: true,
				delta: 0,
				x: e.clientX,
				y: e.clientY,
				initialRange: null,
				selectionDragged: false,
				willDeselectOnPointerUp: false,
			}				
			return;
		}


		clearTimeout(this.startPointerMoveIfNoDragTimeout);
		if( clickedInsideActiveRange && e.detail == 1 ){

			// const willDeselectOnPointerUp = (
			// 	this.CargoEditor.plugins.shadowDom?.canBeEntirelySelected(e.target) &&
			// 	activeRange &&
			// 	activeRange.compareNode(e.target) == activeRange.NODE_INSIDE && 
			// 	activeRange.startContainer == activeRange.endContainer &&
			// 	activeRange.endOffset - activeRange.startOffset == 1
			// );

			this.pointerInfo.willDeselectOnPointerUp = true;

			// preventingDefault will stop us from being able to drag selections of text 
			// so we allow the default to happen and then just immediately re-select the range
			const activeRange = this.CargoEditor.getActiveRange();
			if( activeRange){
				setTimeout(()=>{
					this.CargoEditor.helpers.setActiveRange(activeRange);
				});
			}

			// if clicking on an audio player, media-item etc - don't preventDefault and don't add pointermove
			// to allow immediate pointer-dragging
			if( !this.CargoEditor.plugins.shadowDom?.canBeEntirelySelected(e.target) ){

				FRONTEND_DATA.contentWindow.addEventListener('pointermove', this.onPointerMove)

				this.startPointerMoveIfNoDragTimeout = setTimeout(()=>{
					if( !this.pointerInfo.selectionDragged){
						FRONTEND_DATA.contentWindow.removeEventListener('pointermove', this.onPointerMove)					
					}
				}, 250);
			}
			return;

		} 

			// preventDefault and start pointer listener
		
			// if dragging audio-players, media-items, etc - don't allow selection extension from them
			// a single exception here is made for text icons, which are meant to act like text totally


		if( e.detail == 1){

			if( !this.CargoEditor.plugins.shadowDom?.canBeEntirelySelected(e.target)  ){
				e.preventDefault();	
				FRONTEND_DATA.contentWindow.addEventListener('pointermove', this.onPointerMove)			
			}
		
		// let double/triple clicks go through
		} else if ( e.detail > 1 ){

			if( e.target.tagName == 'DIGITAL-CLOCK' || e.target.tagName == 'TEXT-ICON'){

				const dblClickRange = this.CargoEditor.rangy.createRange();
				dblClickRange.selectNode(e.target);
				this.CargoEditor.helpers.setActiveRange(dblClickRange);

				e.preventDefault();	
				FRONTEND_DATA.contentWindow.addEventListener('pointermove', this.onPointerMove)					
			} else {
				this.CargoEditor.forcedRange = null;
			}
			
			return
		}
		
		if ( clickRange && !correctedRange){

			this.CargoEditor.helpers.setActiveRange(clickRange);

		} else if( correctedRange ){

			this.CargoEditor.helpers.setActiveRange(correctedRange);
		}

			
		this.CargoEditor.events.trigger('cursor-activity', this.activeEditor);

	}

	onEditorLinkPaste = (editor) => {

		cleanupNestedSpansOrLinksOrHeadersCreatedDuringMutation(editor);

	}

	beforeEditorMutationSuccess = (editor) => {

		// check to see if any media items not belonging to this page were added.
		const existingHashes = this.props.pageMedia?.map(media => !media.is_deleted && media.hash);
		const foreignHashes = [];
		editor.generateUnreportedMutationSummaries().forEach(summary => {

			// find foreign media item hashes
			summary.added.forEach(node => {

				if(node.nodeName === "MEDIA-ITEM") {
					const hash = node.getAttribute('hash');

					if(hash && !existingHashes.includes(hash) && !foreignHashes.includes(hash)) { 
						foreignHashes.push(hash);
					}

				}

			})

		})

		// if we found hashes for models not currently present on this page
		if(foreignHashes.length > 0) {

			// compile a list of media from all we have
			const globalImages = _.uniqBy(Object.values(selectors.getMediaByHash(store.getState())), 'id');
			let modelsToCopy = [];

			// compile a list of models we need to copy into this page's media
			foreignHashes.forEach(hash => {
				const model = globalImages.find(model => model.hash === hash);

				if(model) {
					modelsToCopy.push({
						...model,
						// force in_use as the image was found in page content
						in_use: true
					});
				}
			});

			// copy the models into this page
			if(modelsToCopy.length > 0) {

				const { CRDTItem: pageCRDT } = getCRDTItem({
					reducer: "pages.byId",
					item: this.props.pid
				});
	
				// If the images for the page aren't in a CRDT, create a new Y Array to store them
				if (pageCRDT.get("media") instanceof Y.Array === false) {
					const sharedMediaType = new Y.Array();
					sharedMediaType.insert(0, pageCRDT.get("media") || []);
					pageCRDT.set("media", sharedMediaType);
				}
	
				// Convert models from plain objects to proper shared types
				modelsToCopy = modelsToCopy.map(model => convertStateToSharedType(model, new Y.Map())).filter(model => model instanceof Y.Map);
	
				pageCRDT.get('media').push(modelsToCopy);

			}

		}

	}

	shiftSelect = (newRange, e)=>{

		if( !this.pointerInfo.initialRange ){
			return newRange;
		}

		// treat initialRange startPoint as beginning point, as if it were collapsed (should be anyway)
		// newrange is also collapsed
		if( this.CargoEditor.plugins.shadowDom?.canBeEntirelySelected(e.target)){

			if( e.type === 'pointermove'){
				const elRect = e.target.getBoundingClientRect();
				
				if( e.clientX > elRect.left + elRect.width*.5){
					newRange.setStartAfter(e.target);
				} else {
					newRange.setStartBefore(e.target);	
				}

			} else {

				let elementRange = this.CargoEditor.rangy.createRange();
				elementRange.selectNode(e.target);

				let elementRangeStartPosition = this.pointerInfo.initialRange.comparePoint(elementRange.startContainer, elementRange.startOffset);
				let elementRangeEndPosition = this.pointerInfo.initialRange.comparePoint(elementRange.endContainer, elementRange.endOffset);

				if( elementRangeEndPosition > 0 || elementRangeStartPosition > 0){
					newRange.setEndAfter(e.target)
				} else if( elementRangeEndPosition < 0 || elementRangeStartPosition < 0){
					newRange.setStartBefore(e.target)
				}

			}
	
		// don't allow select inside the marquee unless that's where the selection started.
		} else if ( e.target.closest?.('marquee-set') ){
			const closestMarquee = e.target.closest('marquee-set');
			const closestClone = e.target.closest('marquee-inner[slot="contents-clone"]');
			if( !closestMarquee.contains(this.pointerInfo.initialRange.startContainer) || closestClone ){
				const elRect = closestMarquee.getBoundingClientRect();
				
				if( e.clientX > elRect.left + elRect.width*.5){
					newRange.setStartAfter(closestMarquee);
				} else {
					newRange.setStartBefore(closestMarquee);	
				}				
			}
		}

		let cursorRelativePositionStartPosition = this.pointerInfo.initialRange.comparePoint(newRange.startContainer, newRange.startOffset);
		let cursorRelativePositionEndPosition = this.pointerInfo.initialRange.comparePoint(newRange.endContainer, newRange.endOffset);		

		if( cursorRelativePositionStartPosition === 0 && cursorRelativePositionEndPosition === 0){
			return this.pointerInfo.initialRange
		}

		var direction = "neutral"
		const referenceRange = newRange.cloneRange();
		newRange = this.CargoEditor.rangy.createRange();

		if( cursorRelativePositionStartPosition < 0 || cursorRelativePositionEndPosition < 0 ){
			direction = "backward"
			newRange.setStart(referenceRange.startContainer, referenceRange.startOffset)				
			newRange.setEnd(this.pointerInfo.initialRange.endContainer, this.pointerInfo.initialRange.endOffset)
		} else if (cursorRelativePositionStartPosition > 0 || cursorRelativePositionEndPosition > 0){
			direction = "forward"
			newRange.setStart(this.pointerInfo.initialRange.startContainer, this.pointerInfo.initialRange.startOffset)
			newRange.setEnd(referenceRange.startContainer, referenceRange.endOffset);
		} 

		return newRange

		

	}

	forceRangeInsideEditor = (e) => {

		if(!this.activeEditor) {
			return {
				correctedRange: null,
				clickRange: null,
			}
		}

		const editorEl = this.activeEditor.getElement();

		var clickedInEditor = editorEl.contains(e.target);

		if( !clickedInEditor){
			e.preventDefault();
		}


		let boundaryElement = editorEl
		let clickedInBoundary = false;


		// always shift-select if it's pointermove
		var shiftKey = e.shiftKey || e.type === 'pointermove'

		// start by determining a number of bounding elements whose click we want to redirect
		// bodycopy, galleries, column unit, etc
		if( editorEl.contains(e.target) ){
			boundaryElement = helpers.closest(e.target, function (el) {
				if( el.nodeName=="HR"){
					return false;
				}
				return el === editorEl || el.nodeName === 'COLUMN-UNIT' || el.nodeName.startsWith('GALLERY-') || (el.nodeName.startsWith('H') ) 
			});
		}


		const clickedInGallery = helpers.closest(e.target, function (el) {
			return el.nodeName.startsWith('GALLERY-')
		});

		clickedInBoundary = boundaryElement.contains(e.target);

		var activeRange = this.CargoEditor.getActiveRange();

		const boundaryRect = boundaryElement.getBoundingClientRect();

		let boundX = e.clientX;
		let boundY = e.clientY;

		// if the target is inside the boundary element, don't clamp the positions
		if( !clickedInBoundary ){
			boundX = Math.max(boundaryRect.left+1, Math.min(boundaryRect.left+boundaryRect.width +-1, boundX));
			boundY = Math.max(boundaryRect.top+2, Math.min(boundaryRect.top+boundaryRect.height +-2, boundY));
		}

		var clickRange = null;

		if (FRONTEND_DATA.contentWindow.document.caretPositionFromPoint) {
			let caretPosition = FRONTEND_DATA.contentWindow.document.caretPositionFromPoint(boundX, boundY);

			if(caretPosition) {
				clickRange = this.CargoEditor.rangy.createRange();
				clickRange.setStart(caretPosition.offsetNode, caretPosition.offset);
				clickRange.setEnd(caretPosition.offsetNode, caretPosition.offset);
				clickRange.collapse(true);
			}

		} else if (FRONTEND_DATA.contentWindow.document.caretRangeFromPoint) {
			// Use WebKit-proprietary fallback method
			clickRange = FRONTEND_DATA.contentWindow.document.caretRangeFromPoint(boundX, boundY);
		}


		// once we have a click range, check to see if it's close to the click position
		// if it's not , draw a line between the two and see if we hit any big elements.
		// don't correct if we're dragging inside a rotated element..
		if( clickRange && !e.target.closest?.('[uses*="rotation"]') ){

			const rect = clickRange.nativeRange ? clickRange.nativeRange.getBoundingClientRect() : clickRange.getBoundingClientRect();

			// only run a check if we know the range rect is usable
			if( rect.left !== 0 && rect.top !== 0 ){
				const dist = Math.sqrt(Math.pow(rect.left - e.clientX, 2) + Math.pow((rect.top+rect.height/2) - e.clientY, 2));

				// and only run if the distance is significant
				if( dist > Math.max(10, rect.height/2)){

					// depending on distance, make 3-10 checks
					let steps = Math.max(3, Math.min(10, dist/30 ))

					for( let i = 0; i < steps; i++){
						const stepLerp = i/steps;
						const checkX = e.clientX * (1-stepLerp) + rect.left * (stepLerp);
						const checkY = e.clientY * (1-stepLerp) + (rect.top+rect.height/2) * (stepLerp);
						const elAtCoordinates = FRONTEND_DATA.contentWindow.document.elementFromPoint(checkX, checkY);
						if( elAtCoordinates && elAtCoordinates.shadowRoot && editorEl.contains(elAtCoordinates) ){
							clickRange.selectNode(elAtCoordinates);
							clickRange.collapse(e.clientX < checkX)
							break;
						}
					}
				}

			}

		}

		let fallbackRange = false;


		var newRange = this.CargoEditor.rangy.createRange();

		if( !clickRange ){
			fallbackRange = true;
			clickRange = this.CargoEditor.rangy.createRange();

			if( clickedInBoundary && clickedInEditor ){

				if( boundaryElement && boundaryElement.shadowRoot && !this.CargoEditor.plugins.shadowDom?.canBeEntirelySelected(boundaryElement) ){
					clickRange.selectNode(boundaryElement);
				} else {
					clickRange.selectNodeContents(boundaryElement);					
				}
				clickRange.collapse(e.clientX < boundaryRect.left + boundaryRect.width*.5);				


			// range creation failed utterly - time to pull out the big guns
			} else {

				const searchPoints = 30;
				let searchIncrement = 1.033;
				let sweepAngle = 2.399827721492203;

				const elements = [];

				let dist = 15;
				let i, angle =0, posX, posY = 0;
				let el = null;
				let x = e.clientX;
				let y = e.clientY;

				// then, make a sweeping circle around that point, collecting elements that intersect that point
				for(i = 0; i < searchPoints; i++){
					el = null;

					angle = angle + sweepAngle				
					dist = dist*searchIncrement+2

					posX = e.clientX+Math.cos(angle)*(dist);
					posY = e.clientY+Math.sin(angle)*(dist);

					let elAtCoordinates = FRONTEND_DATA.contentWindow.document.elementFromPoint(posX, posY);

					if( !editorEl.contains(elAtCoordinates) || elAtCoordinates == editorEl ){
						elAtCoordinates = null;
						el = null;
					}

					if(elAtCoordinates){

						el = helpers.closest(elAtCoordinates, (node)=>{
							return node.nodeName.startsWith('GALLERY-') || node.nodeName === 'COLUMN-UNIT' || node.nodeName === 'MARQUEE-SET'
						}) || null

						if( !el ){
							el = elAtCoordinates.closest('audio-player, media-item, text-icon, shop-product, digital-clock') || null;
						}
					}

					if(
						el &&
						elements.indexOf(el) === -1 
					){
						elements.push(el);
					}

				 // debug display
	 				// if( !this.disp){
	 				// 	this.disp = [];
	 				// }
	 
	 				// if( !this.disp[i] )  {
	 
	 				// 	this.disp[i] = document.createElement('div');
	 				// 	this.disp[i].style.pointerEvents ='none';
	 				// 	this.disp[i].style.position ='fixed';
	 				// 	this.disp[i].style.top =0
	 				// 	this.disp[i].style.left =0
	 				// 	this.disp[i].style.zIndex = 9999;
	 				// 	this.disp[i].style.width = '10px';
	 				// 	this.disp[i].style.borderRadius='10px';
	 				// 	this.disp[i].style.height = '10px';
	 				// 	this.disp[i].style.marginLeft = '-5px';
	 				// 	this.disp[i].style.marginTop = '-5px';
	 				// 	FRONTEND_DATA.contentWindow.document.body.appendChild(this.disp[i]);
	 				// 	this.disp[i].style.backgroundColor = 'red';					
	 
	 				// }
	 
	 				// this.disp[i].style.transform = `translate3d( ${posX}px, ${posY}px, 0px)`;	
					
				}

				const positions = elements.map(el=>{

 					//  add a bit of dead space around each element to make allowances for drag animation
 					// without it, elements can slide back and forth each frame as it goes in and out of hit-range
 					let clientRect = el.getBoundingClientRect();
 					let rect = {
 						w: clientRect.width,
 						h: clientRect.height,
 						x1: clientRect.left,
 						x2: clientRect.left + clientRect.width,
 						y1: clientRect.top,
 						y2: clientRect.top + clientRect.height,
 					}
 

					let toLeft = false,
						toRight = false,
						distance = 0,
						rise = 0,
						run = 0;

					if ( x >= rect.x1 && x <= rect.x2  ){

						if ( x < rect.x1+rect.w*.5){
							toRight = true;
						} else {
							toLeft = true;
						}

						run = 0;

					} else if ( rect.x1 > x ){

						toRight = true;
						run = rect.x1 - x;

					} else if ( x > rect.x2 ){

						run = rect.x2 - x;		
						toLeft = true;
					}

					if( y >= rect.y1 && y <= rect.y2 ){

						rise = 0;

					} else if ( rect.y1 > y ){

						rise = rect.y1 - y;

					} else if ( y > rect.y2 ){

						rise = rect.y2 - y;					
					}

					distance = Math.sqrt( (rise*rise)+(run*run) );	
					
					return {
						distance,
						rise,
						run,						
						toRight,
						toLeft,
						el,
					}				
				});

				// look for 'important' elements that we want to select
				const closestCharismaticElPosition = _.minBy(positions, (position)=>{
					return position.distance
				})

				if( closestCharismaticElPosition ){
					clickRange.selectNode(closestCharismaticElPosition.el);
					if( closestCharismaticElPosition.toRight ){
						clickRange.collapse(true);
					} else {
						clickRange.collapse(false)
					}
				} else {
					// failsafe. just select something and let the chips fall where they may
					clickRange.selectNodeContents(editorEl)
					clickRange.collapse(true);
				}

			}

		}

		// when clicking into container-only-type elements (like galleries or the column-set element)
		// shift the range to the outside
		// but allow shift-dragging or dragging
		if(
			!shiftKey && 
			(clickRange.startContainer.nodeName.startsWith('GALLERY-') || clickRange.startContainer.nodeName == 'COLUMN-SET')
		){
			var toBeginning = clickRange.startOffset === 0;
			clickRange.selectNode(clickRange.startContainer);
			clickRange.collapse(toBeginning);
		}

		// if we put the initial click inside a shadow root but the target is not a shadow element, correct it
		if( clickRange.startContainer.shadowRoot && !clickRange.startContainer.nodeName.startsWith('GALLERY-')  ){

			const elRect = clickRange.startContainer.getBoundingClientRect();

			clickRange.selectNode(clickRange.startContainer);
			
			if( e.clientX > elRect.left + elRect.width*.5){
				clickRange.collapse(false);
			} else {
				clickRange.collapse(true);
			}
		}

		// copy click range over to rangy object
		newRange.setStart(clickRange.startContainer, clickRange.startOffset);
		newRange.setEnd(clickRange.endContainer, clickRange.endOffset);


		// if we clicked inside an active range, we have to make allowances in order for the natural selection behavior run its cycle
		var clickedInsideActiveRange = clickedInEditor && activeRange && e.type ==='mousedown' && (

			// if the click was inside a text node and the point is inside of the range... 
			(newRange && newRange.commonAncestorContainer.nodeType == Node.TEXT_NODE && activeRange.isPointInRange(newRange.startContainer, newRange.startOffset) )

			// or if it's inside an element node and the node is inside of the range
			|| (activeRange.compareNode(e.target) == activeRange.NODE_INSIDE)
		) && e.target;

		if( clickedInsideActiveRange){
			return {
				clickRange: fallbackRange ? null : newRange,
				correctedRange: null,
				clickedInsideActiveRange: true
			};
		}

		// start by checking if we clicked an element that has shadow root
		// and should be selected in whole when clicked
		if(this.CargoEditor.plugins.shadowDom?.canBeEntirelySelected(e.target) || e.target.nodeName === 'TEXT-ICON' || e.target.nodeName === 'DIGITAL-CLOCK' ){

			// exempt text-icon from click-to-select behavior...
			if(
				(e.target.nodeName !== 'TEXT-ICON' && e.target.nodeName !== 'DIGITAL-CLOCK') ||
				shiftKey 
			){
				newRange.selectNode(e.target);

				return {
					clickRange: newRange,
					correctedRange: newRange
				};			

			} else {

				// use cursor position to determine whether range goes before or after
				const elRect = e.target.getBoundingClientRect();

				newRange.selectNode(e.target);
				
				if( e.clientX > elRect.left + elRect.width*.5){
					newRange.collapse(false);
				} else {
					newRange.collapse(true);
				}

				return {
					clickRange: newRange,
					correctedRange: newRange
				};				

			}

		}




		// in all below scenarios the click target wasn't a media-item or something similarly selectable ( audio-player, etc)
		// if we clicked inside a gallery, we don't do any more manipulation if the selection fails
		if( clickedInGallery && e.target.nodeName.startsWith('GALLERY-')){
			return {
				clickRange: fallbackRange ? null : newRange,
				correctedRange: null
			};
		}

		// if we're shift-selecting, the behavior is mostly unhandled if we're not mousing over a shadowroot element
		if( e.shiftKey){
			return {
				clickRange: newRange,
				correctedRange: null
			};
		}


		let needsCollapse = false;

		// if we didn't click inside the boundary...
		if( !clickedInBoundary && clickedInEditor ){

			var highestLevelChild = newRange.commonAncestorContainer;
			while(
				highestLevelChild &&
				highestLevelChild.parentNode &&
				highestLevelChild.parentNode !== boundaryElement &&
				highestLevelChild!== editorEl &&
				highestLevelChild.parentNode !== editorEl
			){
				highestLevelChild = highestLevelChild.parentNode;
			}

			if( highestLevelChild && highestLevelChild.nodeType == Node.ELEMENT_NODE){
				newRange.selectNode(highestLevelChild);	
				needsCollapse = true;
			}
		}


		// if we placed a range inside something that doesn't want it (non-slotted contents of a shadowrooted element)
		// place the range outside
		let closestSlot = helpers.closest(newRange.commonAncestorContainer, (node)=>{
			return node.nodeType == Node.ELEMENT_NODE && node.hasAttribute('slot');
		})


		let closestSelectableItem = helpers.closest(newRange.commonAncestorContainer, (node)=> {
			return this.CargoEditor.plugins.shadowDom?.canBeEntirelySelected(node)
		});


		if( closestSelectableItem && !closestSlot ){
			newRange.selectNode(closestSelectableItem);
			needsCollapse = true;
		}


		if ( boundaryElement && !boundaryElement.contains(newRange.commonAncestorContainer) ){

			// in this scenario, the range is clearly incorrect
			// so we make a guess by comparing the boundaryRect to the mousedown position
			newRange.selectNodeContents(boundaryElement);
			needsCollapse = true;
		}


		// a range forced into the editor that lands in a marquee will make it tear itself down. shift the selection outside of it
		// a range forced into the editor should not land inside a gallery, only outside of it or on a child element
		if(!clickedInEditor){
			const closestMarqueeSet = newRange.startContainer.parentElement.closest('marquee-set');
			const closestGallery =  helpers.closest((
				newRange.startContainer.nodeType == Node.ELEMENT_NODE ? newRange.startContainer : newRange.startContainer.parentElement
				), (el)=>{
				return el.nodeName.startsWith('GALLERY-');
			})

			const closestColumnSet = newRange.startContainer.parentElement.closest('column-set');

			if( closestMarqueeSet || closestGallery || closestColumnSet){

				newRange.selectNode(closestMarqueeSet || closestGallery || closestColumnSet);
				needsCollapse = true;
			} else {
				if( e.clientY > boundaryRect.top+boundaryRect.height){
					newRange.selectNodeContents(editorEl);
					newRange.collapse(false)
				} else if ( e.clientY < boundaryRect.top ){
					newRange.selectNodeContents(editorEl);
					newRange.collapse(true)
				}
			}

		}


		if( newRange.startContainer.nodeType == Node.ELEMENT_NODE && needsCollapse){
			var nodes = Array.from(newRange.startContainer.childNodes);
			var startOffset = newRange.startOffset;

			while (this.CargoEditor.helpers.isWhitespaceNode(nodes[startOffset]) ){
			    startOffset++
			}

			newRange.setStart(newRange.startContainer, startOffset);
		
		}

		if( newRange.endContainer.nodeType == Node.ELEMENT_NODE && needsCollapse){
			var nodes = Array.from(newRange.endContainer.childNodes);
			var endOffset = newRange.endOffset;

			while (this.CargoEditor.helpers.isWhitespaceNode(nodes[endOffset-1])) {
			    endOffset--;
			}

			newRange.setEnd(newRange.endContainer, endOffset);

		}

		if( newRange.startContainer.nodeType == Node.ELEMENT_NODE){
			var el = clickRange.startContainer.childNodes[clickRange.startOffset];

			if( el && el.nodeName =='BR'){
				el = el.previousSibling;
			}
			// if clicking directly below a block-level element with a break under it, we stop there
			if (
				el && (
					el.nodeName.startsWith('GALLERY-') || el.nodeName.startsWith('MARQUEE-') || el.nodeName !== 'COLUMN-SET'
				)
			){
			} else {

				while( el && el.previousSibling && this.CargoEditor.helpers.isWhitespaceNode(el) ){
					el = el.previousSibling
					newRange.selectNode(el);
					newRange.collapse(false);
				}					
			}

		}

		const newRangeRect = newRange.nativeRange.getBoundingClientRect();

// 		var rectTest = FRONTEND_DATA.contentWindow.document.querySelector('#rect-test');
// 		if( !rectTest){
// 			rectTest = FRONTEND_DATA.contentWindow.document.createElement('div');
// 			rectTest.setAttribute('id', 'rect-test');
// 
// 			FRONTEND_DATA.contentWindow.document.body.appendChild(rectTest)
// 			rectTest.style.pointerEvents = 'none';
// 			rectTest.style.position = 'fixed';
// 			rectTest.style.zIndex = 9999999;
// 			rectTest.style.outline = '1px solid green';
// 		}
// 		rectTest.style.top = newRangeRect.top +'px'		
// 		rectTest.style.left = newRangeRect.left +'px'		
// 		rectTest.style.height = newRangeRect.height +'px'		
// 		rectTest.style.width = newRangeRect.width +'px'		


		const run = e.clientX - (newRangeRect.left + newRangeRect.width *.5);
		const rise = e.clientY - (newRangeRect.top + newRangeRect.height *.5);

		// compare the two values, and if one is more drastic than the other we use it to set the range above/below
		if(
			needsCollapse &&
			newRangeRect.height  > 0 &&
			newRangeRect.width > 0 &&
			newRangeRect.top !== 0 &&
			newRangeRect.left !== 0 &&
			(
				Math.abs(run) > Math.abs(rise) ||
				e.clientX < boundaryRect.left + 20 ||
				e.clientX > boundaryRect.left + boundaryRect.width + -20
			)
		){
			newRange.collapse(run < 0);
		} else if (needsCollapse){
			newRange.collapse(rise < 0)			
		}

		return {
			clickRange: newRange,
			correctedRange: newRange
		};
	}



	onEditorCursorActivity = _.throttle(() => {

		this.setState(state => {
			return {
				editorContext: {
					...state.editorContext,
					range: this.getRangeInformation(),
				}
			}
		});

	}, 250, {
		leading: true,
		trailing: true
	})

	onEditorMutationEnd = (editor, options = {}) => {

		if(editor.getExecutionDepth() > 0) {
			// don't run this while we're still running nested mutations. Only run after 
			// everything is in place
			return;
		}

		if (
			// don't listen to stale events
			!editor 
			|| !this.activeEditor
			|| editor !== this.activeEditor 
			|| editor.destroyed 
			|| this.destroyed
			// or if the mutation should not cause draft mode
			|| options.preventDraft === true
		) {
			return;
		}

		// check range after mutation has ended
		this.onEditorCursorActivity();

		if(this.activeEditor.pid === this.props.pid) {

			// if we don't have a DOM binding already: init one
			createDOMBinding(this.props.pid, {
				initUsingDOM: true
			});

		} else {
			console.log('Unable to initialize DOM binding due to mismatching PIDs');
		}

		if(!domBindingsMap.has(this.activeEditor.pid)) {
			console.log('No dom binding. Your content might not be saved.');
			debugger;
		}

		lastEditorRangeAtMutationEnd = this.getRangeSummary(this.CargoEditor.getActiveRange());

	}

	onEditorMutationStart = () => {

		lastEditorRangeAtMutationStart = this.getRangeSummary(this.CargoEditor.getActiveRange());

	}

	getRangeInformation = () => {

		const result = {
			available: false,
			commands: {},
			priorityCommand: null,
			activeRange: null
		}

		if (!this.CargoEditor) {
			return result;
		}

		const range = this.CargoEditor.getActiveRange();

		// store the range used to get these results in case
		// components deeper down need to grab more information
		result.activeRange = range;

		if (
			range === undefined ||
			this.activeEditor === undefined ||
			!this.CargoEditor.helpers.inSameEditingHost(
				range.commonAncestorContainer,
				this.activeEditor.getElement())
		) {
			for (let commandName in this.state.editorContext.getCommandState) {
				result.commands[commandName] = { isApplied: false, isAvailable: false };
			}
			return result;
		}

		let activePriority = -1;
		for (let commandName in this.state.editorContext.getCommandState) {
			result.commands[commandName] = this.state.editorContext.getCommandState[commandName].getState(range);
			if (
				result.commands[commandName].isApplied &&
				this.state.editorContext.getCommandState[commandName].priority > activePriority
			) {
				result.priorityCommand = commandName;
				activePriority = this.state.editorContext.getCommandState[commandName].priority;
			}
		}

		return result;

	}

	componentDidMount() {

		if (this.props.pid !== this.props.PIDBeingEdited) {
			this.prepareFrontendForEditing({
				pidChanged: true
			});
		}

	}

	componentDidUpdate(prevProps, prevState, snapshot) {

		if(
			this.activeEditor
			// pid stayed the same
			&& this.props.pid === prevProps.pid
			// but sort changed
			&& this.props.sort !== prevProps.sort
		) {

			requestAnimationFrame(() => {

				// page got sorted. Make sure it's still in view
				const pageRect = this.activeEditor?.getElement().getBoundingClientRect();

				if(pageRect && (pageRect.bottom < 0 || pageRect.top > FRONTEND_DATA.contentWindow.innerHeight)) {
					this.activeEditor.getElement().scrollIntoView();
				}

			})
		}

		if (this.props.pid !== this.props.PIDBeingEdited) {
			this.prepareFrontendForEditing({
				pidChanged: this.props.pid !== prevProps.pid
			});
		}

		if ( 
			this.props.pid 
			// If getting an update to a page, but not when switching pages
			&& this.props.pid === prevProps.pid
			// and we have page data
			&& this.props.hasPage
		) {

			// gather a list of valid thumbnail candidates
			const validMedia = this.props.pageMedia ? this.props.pageMedia.filter((m) => m.is_deleted !== true && m.loading === undefined) : [];
			const currentThumbMediaId = this.props.pageThumbMediaId || null;

			if ( 
				// If page thumbnail ID has not been set
				currentThumbMediaId === null
				// or page media no longer contains the page's thumbnail media
				|| (
					prevProps.pageThumbMediaId 
					&& validMedia.some((m) => m.id === prevProps.pageThumbMediaId ) === false
				)
			) {

				// Find a valid media ID to use as thumbnail id
				let newThumbMediaId = validMedia.length > 0 ? validMedia[0].id : null;

				// Set the new thumbnail id
				if ( currentThumbMediaId !== newThumbMediaId ) {

					const { CRDTItem: draftPage } = getCRDTItem({
						reducer: 'pages.byId',
						item: this.props.pid
					});

					applyChangesToYType(draftPage, {
						thumb_media_id: newThumbMediaId
					}, YTransactionTypes.NotUndoable);

				}
			}
			

		}

		// legacy. This can probably be removed in lieu of the page removal detection in the store 
		// listener in the constructor
		if (
			// first check to see if we're still editing the same page
			this.props.PIDBeingEdited === this.props.pid
			// but the page was previously rendered
			&& prevProps.PIDHasRendered === true
			// and now is not rendered anymore
			&& this.props.PIDHasRendered === false
		) {

			this.destroyInlineEditor();

		}


		if(
			this.activeEditor
			&& this.props.editor_spellcheck !== prevProps.editor_spellcheck
		) {
			this.activeEditor.getElement().setAttribute('spellcheck', this.props.editor_spellcheck)
		}

		if (this.props.hasPage && prevProps.hasPage) {

			// handle page deletion
			if (
				prevProps.pageCrdtState !== this.props.pageCrdtState
				&& this.props.pageCrdtState === CRDTState.Deleted
			) {
				this.navigateHome();
			}

			// handle image delection
			if (
				this.activeEditor
				// we're not switching pages
				&& this.props.pid === prevProps.pid
				// but the page media array was altered
				&& this.props.pageMedia !== prevProps.pageMedia
				// don't remove images from a page that was already deleted.
				&& this.props.pageCrdtState !== CRDTState.Deleted
			) {

				const oldImagesByHash = _.groupBy(prevProps.pageMedia, 'hash');
				const newImagesByHash = _.groupBy(this.props.pageMedia, 'hash');

				_.each(newImagesByHash, ([newImage], hash) => {

					const oldImage = oldImagesByHash[hash]?.[0];

					if (
						// new version of the image is deleted
						newImage && newImage.crdt_state === CRDTState.Deleted
						// old version was not
						&& oldImage && oldImage.crdt_state !== CRDTState.Deleted
					) {

						// image got freshly deleted. Find any references in the editor and delete them
						const imagesInContent = this.activeEditor.getElement().querySelectorAll(`[hash="${hash}"]`);

						if (imagesInContent.length > 0) {

							this.CargoEditor.mutationManager.execute(() => {

								imagesInContent.forEach(image => {
									image.remove();
								});

							});
						}
					}
				})

			}

		}

	}

	prepareFrontendForEditing(options = {}) {

		// not editing a pid anymore
		if (!this.props.pid) {

			// destroy inline editor
			this.destroyInlineEditor();

			this.props.updateFrontendState({
				PIDToEdit: undefined
			});

			// nothing else to do
			return;
		}

		if(options.pidChanged) {
			this.props.updateFrontendState({
				PIDToEdit: this.props.pid
			});
		}

		if(this.props.currentPIDIsSet) {

			// Sets cannot be edited directly. Navigate to the first editable
			// child in the set
			getFirstPageInSet(this.props.pid).then(pidToEdit => {
				this.props.history.push(`/${pidToEdit || ""}`);
			});

			return;

		}

		// no page loaded yet
		if (!this.props.hasPage) {

			// cache pid value so it's still the same when our async promise resolves.
			const pid = this.props.pid;

			// only fetch pages once
			if (!contentFetchesInProgress.includes(pid)) {

				// add pid to list so we know it's in progress
				contentFetchesInProgress.push(pid);

				// Load the page data
				this.props.fetchContent(pid, {
					idType: 'pid'
				}).then(() => {

					// if successful, remove pid from list
					contentFetchesInProgress = contentFetchesInProgress.filter(i => i !== pid);

				}).catch(() => {

					this.navigateHome();

				});

			}

			// Do nothing till that's done
			return;
		}

		// page was previously deleted.
		if (this.props.pageCrdtState === CRDTState.Deleted) {

			this.navigateHome();

			return;

		}

		// ensure pins are initially rendered in the correct viewport mode
		if(
			options.pidChanged
			&& this.props.pageIsPinned
		) {

			if(
				this.props.pagePinOptions?.screen_visibility === 'mobile'
				&& this.props.viewport !== 'mobile'
			) {

				// set viewport to mobile, otherwise we won't render a mobile pin
				this.props.updateAdminState({'viewport': 'mobile'});

			} else if(
				this.props.pagePinOptions?.screen_visibility === 'desktop'
				&& this.props.viewport !== 'desktop'
			) {

				// set viewport to mobile, otherwise we won't render a mobile pin
				this.props.updateAdminState({'viewport': 'desktop'});

			}

		}

		// ensure homepages are initially rendered in the correct viewport mode
		if(this.props.desktopHomepageId !== this.props.mobileHomepageId) {
			
			if (
				this.props.pid === this.props.desktopHomepageId
				&& this.props.desktopHomepageId !== this.props.mobileHomepageId
				&& this.props.viewport !== 'desktop'
			) {
				this.props.updateAdminState({'viewport': 'desktop'});
			}

			if (
				this.props.pid === this.props.mobileHomepageId
				&& this.props.desktopHomepageId !== this.props.mobileHomepageId
				&& this.props.viewport !== 'mobile'
			) {
				this.props.updateAdminState({'viewport': 'mobile'});
			}

		}

		// make sure frontend is rendering the correct URL
		if (this.props.PIDToRender) {

			let newPath;

			if (this.props.PIDToRender === 'root') {
				newPath = '/';
			} else {
				newPath = `/pid/${this.props.PIDToRender}`;
			}

			if(this.props.pagePurl) {
				newPath += '#' + this.props.pagePurl
			}

			if (FRONTEND_DATA.history.location.pathname + FRONTEND_DATA.history.location.hash !== newPath) {
				try {
					FRONTEND_DATA.history.replace(newPath)
				} catch(e) {
					console.error(e);
				}
			}

		}

		// check to see if the page container exists in the frontend
		if (this.props.PIDHasRendered) {

			// we have the page rendered, attach an editor
			this.makePageInlineEditable();

		}

	}

	navigateHome() {

		getDefaultEditingPID().then(pidToEdit => {

			this.props.history.push(`/${pidToEdit || ""}`)

			if (pidToEdit === undefined) {
				// we have nothing to edit. Also navigate the frontend home
				FRONTEND_DATA.history.replace('/')
			}

		});

	}

	componentWillUnmount() {

		
		this.unsubscribeFromStore();

		if (this.CargoEditor) {

			this.CargoEditor.events.off('cursor-activity', this.onEditorCursorActivity);
			this.CargoEditor.events.off('mutation-end', this.onEditorMutationEnd);
			this.CargoEditor.events.off('mutation-start', this.onEditorMutationStart);
			this.CargoEditor.events.off('editor-activated', this.gainFocus);
			this.CargoEditor.events.off('editor-deactivated', this.loseFocus);
			this.CargoEditor.events.off('editor-no-content', this.editorIsEmpty);
			this.CargoEditor.events.off('editor-has-content', this.editorHasContent);
			this.CargoEditor.events.off('mutation-before-success', this.beforeEditorMutationSuccess);
			this.CargoEditor.events.off('editor-pasted-link', this.onEditorLinkPaste);

		}

		globalDragEventController.off('dragend', this.onDragEnd);
		globalDragEventController.off('dragstart', this.onDragStart);
		globalDragEventController.off('drop', this.onEditorDrop);
		globalDragEventController.off('before-drop', this.onBeforeEditorDrop);

		// unbind from the undo manager
		globalUndoManager.off('yjs-stackitem-start', this.onYJSUndoStackItemStarted);
		globalUndoManager.off('yjs-stackitem-finalized', this.onYJSUndoStackItemFinalized);
		globalUndoManager.off('yjs-before-stackitem-applied', this.beforeYJSUndoStackItemApplied);
		globalUndoManager.off('yjs-stackitem-applied', this.onYJSUndoStackItemApplied);

		this.unbindContentWindowEvents();
		this.destroyInlineEditor();

		this.destroyed = true;

	}

	destroyInlineEditor() {

		if (this.activeEditor) {

			// destroy any active editors.
			try {
				this.activeEditor.destroy();
			} catch(e) {
				// this can error out when the content frame is removed and the editor gets destroyed after. Just catch
				// it so we can show the proper error
				console.error(e);
			}

			// Stop observing changes when not actively editing
			domBindingsMap.get(this.props.PIDBeingEdited)?.stopMutationObserver();

			delete this.activeEditor;
		}

		// cancel any pending throttled functions
		this.onEditorCursorActivity.cancel();

		this.props.updateFrontendState({
			PIDBeingEdited: undefined
		});

	}

	editorHasContent = (editor) => {

		if (editor === this.activeEditor) {
			editor.getElement().classList.remove('empty-editor')
		}

	}

	editorIsEmpty = (editor) => {

		if (editor === this.activeEditor) {
			editor.getElement().classList.add('empty-editor')
		}

	}

	makePageInlineEditable(el) {

		const pageEl = el || _.findLast(this.props.renderedPages, el => el.id === this.props.pid);
		const pagePID = this.props.pid;

		// do not try to add an editor to the same element more than once
		if(this.elementsAwaitingEditor.includes(pageEl)) {
			return;
		}

		// we have a proper ref to the page and we're not already editing this PID
		if (pageEl && this.props.PIDBeingEdited !== this.props.pid) {

			this.elementsAwaitingEditor.push(pageEl);
			
			// immediately destroy the old editor
			this.destroyInlineEditor();


			// attach editor
			this.loadInlineEditor().then(CargoEditor => {

				// frontend sends the click event that started the editor so we can
				// place the cursor at the initial click location
				const activationEvent = window.editorActivationEvent;

				// make sure this is not reused
				delete window.editorActivationEvent;

				// if the page el has since been removed, abort.
				if (!FRONTEND_DATA.contentWindow.document.contains(pageEl)) {
					return;
				}

				const editorElement = pageEl.querySelector('bodycopy');

				if(!editorElement) {
					// page probably is now rendered as sparse. Cancel
					console.log('cannot add editor to sparse page')
					return;
				}

				this.activeEditor = CargoEditor.addEditor(editorElement);

				this.props.updateFrontendState({
					PIDBeingEdited: this.props.pid
				});

				if(this.props.editor_spellcheck) {
					this.activeEditor.getElement().setAttribute('spellcheck', true)
				}

// 				this.activeEditor.addElementEventListener('contextmenu', (e) => {
// 					e.preventDefault();
// 
// 					// OPEN CONTEXT MENU HERE...
// 
// 				})

				// store PID on the editor
				this.activeEditor.pid = pagePID;

				if (
					(
						// if there's something focused in the current doc
						document.contains(document.activeElement)
						&& (
								(
									// and that something is an input field
									document.activeElement.tagName === "INPUT"
									// with a type of text
									&& document.activeElement.type === "text"
								) 
							|| document.activeElement.classList.contains('admin-content-editable-input')
						)
						// only steal focus if all of the above is not true
					) !== true
				) {

					// we have the source event of this editor's activation. Place a cursor at the click coords
					if(activationEvent) {

						const cursorPos = this.activeEditor.core.helpers.createRangeAtCoordinates(
							activationEvent.clientX, 
							activationEvent.clientY
						);

						FRONTEND_DATA.contentWindow.getSelection().removeAllRanges();
						FRONTEND_DATA.contentWindow.getSelection().addRange(cursorPos);

						if(activationEvent.fileArray !== undefined) {
							// forward the drop event that intialized this editor
							CargoEditor.events.trigger('editor-drop', this.activeEditor, activationEvent);
						}

					} else {
							this.activeEditor.getElement().focus({
								preventScroll: true
							});
					}

				}

				if(this.CargoEditor.getActiveRange()) {
					// jiggle the range. Sometimes after entering sleep mode it wouldn't visually display
					// the range even though it was still there
					try {
						this.CargoEditor.helpers.setActiveRange(this.CargoEditor.getActiveRange().cloneRange());
					} catch {
						// fail silently
					}
				}

				// Chrome has a race condition bug where sometimes the .focus() event callback
				// does not fire until the iFrame itself is interacted with. This will manually
				// trigger a activation event so that we at least have an active editor to work with till
				// the iframe is clicked. Fixes code view on direct load
				if (!CargoEditor.getActiveEditor()) {
					CargoEditor.events.trigger('editor-activated', this.activeEditor);
				}

				// cancel any ongoing throttling
				this.onEditorCursorActivity.cancel();

				// update the editorContext with the current range info
				this.onEditorCursorActivity();

				// check if the editor has content or not
				if(this.activeEditor.checkIfEmpty()) {
					this.editorIsEmpty(this.activeEditor);
				}

				this.elementsAwaitingEditor = this.elementsAwaitingEditor.filter(el => el !== pageEl);

				// start observing changes if we have a dombinding already
				domBindingsMap.get(this.props.pid)?.startMutationObserver();

			})
		}

	}

}

function mapReduxStateToProps(state, ownProps) {

	const pid = state.adminState.matchedRoute?.params?.pid;
	const page = state.pages.byId[pid];
	const currentPIDIsSet = state.sets.byId[pid] !== undefined;

	const PIDToRender = helpers.getCorrectRenderContextForGivenPID(state, selectors, pid);

	return {
		viewport: state.adminState.viewport,
		PIDBeingEdited: state.frontendState.PIDBeingEdited,
		renderedPages: state.frontendState.renderedPages,
		PIDHasRendered: state.frontendState.activePID === PIDToRender,
		editor_spellcheck: state.adminState.localStorage.editor_spellcheck,
		pid,
		PIDToRender,
		// Don't import the entire page. It's content field updates
		// a lot and will cause unneeded re-renders
		hasPage: page !== undefined,
		currentPIDIsSet,
		pageCrdtState: page?.crdt_state,
		pagePinOptions: page?.pin_options,
		pageIsPinned: page?.pin,
		pageIsOverlaid: page?.overlay,
		pageMedia: page?.media,
		pagePurl: page?.purl,
		pageThumbMediaId: page?.thumb_media_id,
		desktopHomepageId: state.site.homepage_id,
		mobileHomepageId: state.site.mobile_homepage_id,
		sort: state.structure.bySort[pid]
	};

}

PageEditor.contextType = AlertContext;

function mapDispatchToProps(dispatch) {

	return bindActionCreators({
		removeUIWindow: actions.removeUIWindow,
		addUIWindow: actions.addUIWindow,
		updateAdminState: actions.updateAdminState,
		updateFrontendState: actions.updateFrontendState,
		fetchContent: actions.fetchContent
	}, dispatch);

}

export default withRouter(
	connect(
		mapReduxStateToProps,
		mapDispatchToProps
	)(PageEditor)
);
